import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastContextType {
  toast: any;
  setToast: (value: any) => void;
}

export const ToastContext = createContext<ToastContextType>({} as any);

const ToastProvider: FC<ReactNode> = ({ children }: any) => {
  const [toast, setToast] = useState(null as any);

  useEffect(() => {
    if (toast) {
      toast(toast.message, {
        type: toast.type || 'info',
        position: toast.position || 'top-right',
        autoClose: toast.autoClose || 5000,
        hideProgressBar: toast.hideProgressBar || false,
        closeOnClick: toast.closeOnClick || true,
        pauseOnHover: toast.pauseOnHover || true,
        draggable: toast.draggable || true,
        progress: toast.progress,
      });
    }
  }, [toast]);

  return (
    <ToastContext.Provider
      value={{
        toast,
        setToast,
      }}
    >
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
