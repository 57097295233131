import styled from 'styled-components';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-self: center;
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 50%;
  border: 5px solid #18737a;

  @media (min-width: 800px) {
    width: 250px;
    height: 250px;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
`;

export const ChangeAvatar = styled(Button)`
  && {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* background-color: #555; */
    background-color: rgba(85, 85, 85, 0.3);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px 5px 50% 50%;

    :hover {
      background-color: #777;
    }

    @media (min-width: 800px) {
      border-radius: 5px;
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledTextField = styled(TextField)`
  && {
    margin-top: 20px;
    width: 80%;
    max-width: 550px;
    align-self: center;
  }
`;

export const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
    width: 80%;
    max-width: 550px;
    align-self: center;
    @media (min-width: 800px) {
      height: 50px;
      width: 200px;
    }
  }
`;
