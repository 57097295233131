import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
     * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    *, button, input {
        border: 0;
        background: none;
        font-family: --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    html {
        background: var(--primary);
    } 
    :root {
        --primary: whitesmoke;
        --secondary: #15181C;
        --white: white;
        --dark-hover: olive;
        --light-hover: teal;
        --gray: #3d3d3d;
        --teal: #18737a;
        --brown: #383838;

    }
`;
