import './styles.css';

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="loader">Carregando...</div>
    </div>
  );
};

export default Spinner;
