import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { adaptV4Theme } from '@mui/material/styles';
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './config/firebase';
import TextProvider from './context/text';
import ToastProvider from './context/toast';
import { UserProvider } from './context/user';
import Routes from './routes';
import GlobalStyles from './styles/GlobalStyles';

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

// const theme = createTheme(
//   adaptV4Theme({
//     palette: {
//       primary: {
//         main: '#05786d',
//       },
//       secondary: {
//         main: '#04b4b7',
//         contrastText: '#f9f7f7',
//       },
//     },
//   })
// );

const theme = createTheme({
  palette: {
    primary: {
      main: '#05786d',
    },
    secondary: {
      main: '#04b4b7',
      contrastText: '#f9f7f7',
    },
  },
});

const App = () => {
  return (
    <UserProvider>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <ToastProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <TextProvider>
                <Routes />
                <GlobalStyles />
              </TextProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ToastProvider>
      </FirebaseAppProvider>
    </UserProvider>
  );
};

export default App;
