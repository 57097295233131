import { createContext, useState, useContext } from 'react';

const TextContext = createContext();

export default function TextProvider({ children }) {
  const [text, setText] = useState('');
  const [cleanText, setCleanText] = useState('');

  return (
    <TextContext.Provider
      value={{
        text,
        setText,
        cleanText,
        setCleanText,
      }}
    >
      {children}
    </TextContext.Provider>
  );
}

export function useText() {
  const context = useContext(TextContext);
  if (!context) throw new Error('useText must be used within a TextProvider');
  const { text, setText, cleanText, setCleanText } = context;
  return { text, setText, cleanText, setCleanText };
}
