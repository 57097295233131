import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import {
  AvatarContainer,
  StyledLabel,
  ChangeAvatar,
} from '../pages/MyProfile/styles';
import AddPhotoIcon from '@mui/icons-material/AddAPhoto';
import { profilePlaceholder } from '../assets/images';
import {
  auth,
  createUserProfileDocument,
  db,
  storage,
} from '../config/firebase';
import { doc, onSnapshot, writeBatch } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';

export interface User {
  uid: string;
  email: string;
  firstname?: string;
  lastname?: string;
  photoURL?: string;
  username?: string;
}

type AuthContextType = {
  isAuthenticated: boolean;
  userHasAuthenticated: (value: boolean) => void;
  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;
  isAuthenticating: boolean;
};

export const UserContext = createContext<AuthContextType>({
  isAuthenticated: false,
  userHasAuthenticated: () => {},
  currentUser: null,
  setCurrentUser: () => {},
  isAuthenticating: true,
});

type UserProviderProps = {
  children: ReactNode;
};

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState<string | null>(null);
  const [mounted, setMounted] = useState(true);
  const [progress, setProgress] = useState(0);

  const batch = writeBatch(db);
  const userRef = doc(db, 'users', `${currentUser?.uid}`);

  useEffect(() => {
    if (!mounted) {
      // Check if the user is logged in on first load (local storage)
      const localUser = localStorage.getItem('user');
      if (localUser) {
        setCurrentUser(JSON.parse(localUser));
        setIsAuthenticated(true);
      }
    }

    return () => {
      setMounted(true);
    };
  }, [mounted]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        setIsAuthenticating(true);
        const userRef: any = await createUserProfileDocument(userAuth);
        onSnapshot(userRef, (snapshot: any) => {
          setCurrentUser({
            id: snapshot.id,
            ...snapshot.data(),
          });
          localStorage.setItem(
            'user',
            JSON.stringify({
              id: snapshot.id,
              ...snapshot.data(),
            })
          );
          setIsAuthenticated(true);
          if (!snapshot.data()?.username) {
            return setOpenDialog(true);
          }
        });
      } else {
        setCurrentUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem('user');
      }
      setIsAuthenticating(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleUsername = async () => {
    batch.set(
      userRef,
      {
        username,
      },
      { merge: true }
    );
    return await batch.commit().then(() => {
      toast.success('Username Alterado!');
      setOpenDialog(false);
    });
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    if (!image) {
      console.error('Nenhuma imagem selecionada!');
      return;
    }
    const newAvatarRef = doc(db, 'users', `${currentUser?.uid}`);
    const photoName = new Date().toUTCString();
    const storageRef = ref(storage, `profile/${currentUser?.uid}/${photoName}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      function (snapshot) {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            console.log('Upload Running');
        }
      },
      function (error) {
        console.log('Error', error.message);
      },
      async function () {
        await getDownloadURL(uploadTask.snapshot.ref).then(async function (
          downloadURL
        ) {
          console.log('File available at', downloadURL);
          batch.set(
            newAvatarRef,
            {
              photoURL: downloadURL,
            },
            { merge: true }
          );
          setProgress(0);
        });
        return await batch.commit().then(() => {
          toast.success('Foto alterada!');
        });
      }
    );
  };

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        userHasAuthenticated: setIsAuthenticated,
        currentUser,
        setCurrentUser,
        isAuthenticating,
      }}
    >
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Bem-vindo</DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <DialogContentText>
            <b>Nos diga como devemos te chamar?</b>
            <br />
            <small>
              Isso será exibido no seu perfil e nas promoções em que você
              interagiu. Também pode ser modificado a qualquer momento nas
              configurações do seu perfil.
            </small>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nome de usuário"
            type="username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            fullWidth
            style={{ marginBottom: '20px' }}
          />
          <DialogContentText>
            <b>E sobre a sua aparência?</b>
            <br />
            <small>
              Use sua própria foto ou faça upload de qualquer imagem para ser o
              seu avatar no PromoHunter.
              <i>*Isso é totalmente opcional.</i>
            </small>
          </DialogContentText>
          {currentUser ? (
            <AvatarContainer
              style={{
                backgroundImage: `url(${
                  currentUser?.photoURL
                    ? currentUser?.photoURL
                    : profilePlaceholder
                })`,
                backgroundSize: 'cover',
              }}
            >
              <input
                id="imageUpload"
                type="file"
                accept="image/*"
                onChange={handleUpload}
                style={{ display: 'none' }}
              />
              <StyledLabel htmlFor="imageUpload">
                <ChangeAvatar
                  startIcon={<AddPhotoIcon />}
                  aria-label="adicionar"
                >
                  {progress
                    ? 'Enviando... ' + progress + '%'
                    : 'Alterar avatar'}
                </ChangeAvatar>
              </StyledLabel>
            </AvatarContainer>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUsername} color="primary">
            Concluir
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </UserContext.Provider>
  );
};
