import { lazy, Suspense, useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Spinner from './components/Spinner';
import { UserContext } from './context/user';

const Layout = lazy(() => import('./pages/Layout'));
const PromoPage = lazy(() => import('./pages/PromoPage'));
const EditPromo = lazy(() => import('./pages/EditPromo'));
const NewPromo = lazy(() => import('./pages/NewPromo'));
const SignIn = lazy(() => import('./pages/SignIn'));
const SignUp = lazy(() => import('./pages/SignUp'));
const MyProfile = lazy(() => import('./pages/MyProfile'));
const PublicProfile = lazy(() => import('./pages/PublicProfile'));
const MyPromos = lazy(() => import('./pages/MyPromos'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Privacy = lazy(() => import('./pages/Privacy'));

export default function Router() {
  const { isAuthenticated, currentUser } = useContext(UserContext);

  const location = useLocation();

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/promo/:promoLink" element={<PromoPage />} />
        <Route path="/promo/:promoLink/edit" element={<EditPromo />} />
        <Route path="/privacy" element={<Privacy />} />

        {!isAuthenticated ? (
          <>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
          </>
        ) : null}

        {currentUser ? (
          <>
            <Route path="/profile/promos" element={<MyPromos />} />
            <Route path="/profile/:username" element={<PublicProfile />} />
            <Route path="/newpromo" element={<NewPromo />} />
            <Route path="/profile" element={<MyProfile />} />
          </>
        ) : null}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
